import Swal from 'sweetalert2'

const StartLoading = ({ commit, state }) => {
	commit('ADD')
}

const FinishLoading = ({ commit, state }) => {
	commit('REM')
}

const GetModulesAndModalities = ({ commit, dispatch }, payload ) => {

	dispatch('StartLoading')

	return window.api.call('post', '/get-modules-and-modalities', {
			account_id: 	payload
		})
		.then(({data}) => {

			if(data.response)
			{
				commit('UPDATE_MODULES', data.modules)
				commit('UPDATE_MODALITIES', data.modalities)
			}	

		})
		.finally(() => {
			dispatch('FinishLoading')
		})
}

const GetFaqItems = ({ commit, dispatch }, payload) => {
	dispatch('StartLoading')

	return window.api.call('get', '/faq/get-faq-items?all_data=true')
		.then(({data}) => {
			if(data.response)
			{
				commit('UPDATE_ALL_ARTICLES', data.articles)
				commit('UPDATE_ALL_AUTHORS', data.authors)
				commit('UPDATE_ALL_CATEGORIES', data.categories)

				let sections = []

				data.categories.forEach(c => {
					sections.push(...c.sections)
				})

				commit('UPDATE_ALL_SECTIONS', sections)
			}
		})
		.finally(() => {
			dispatch('FinishLoading')
		})
}

const GetOnlineAccounts = ({ commit, state, dispatch }, payload) => {
	const online_users = state.online_accounts
		.filter(acc => acc.type == 'user')
	
	const online_accounts = state.online_accounts
		.filter(acc => acc.type == 'account')
		.map(acc => acc.id)

	return window.api.call('post', '/get-online-accounts', {
		accounts:			online_accounts,
	})
		.then(async ({data}) => {
			if(data.response && data.accounts.length) {
				commit('RESET_ONLINE_ACCOUNTS', [ ...data.accounts, ...online_users])

				return
			}
		})
}

const AddOnlineAccount = ({ commit }, payload) => {
	commit('ADD_ONLINE_ACCOUNT', payload)
}

const RemOnlineAccount = ({ commit }, payload) => {
	commit('REM_ONLINE_ACCOUNT', payload)
}

const ResetOnlineAccounts = ({ commit }, payload) => {
	commit('RESET_ONLINE_ACCOUNTS', payload)
}

export {
	StartLoading,
	FinishLoading,
	GetModulesAndModalities,
	GetFaqItems,
	AddOnlineAccount,
	RemOnlineAccount,
	ResetOnlineAccounts,
	GetOnlineAccounts
}